import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { DEALER_INFORMATION_ROUTE } from 'constants/routes';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

// q is a string with any existing query parameters, always include & before each value
export const idsToLinks = (ids: string[], url: string, key: string, q = '') => {
  const dataLength = ids.length;

  const elements = ids.map((val, index) => {
    const keyField = +`${key}${val}`;
    return (
      <React.Fragment key={keyField}>
        <Link
          className="remove-underline"
          key={keyField}
          to={`${url}?id=${val}${q}`}
        >
          {val}
        </Link>
        {index < dataLength - 1 && ', '}
      </React.Fragment>
    );
  });
  return elements;
};

export const DealerHome: React.FC = () => {
  const { t } = useTranslation();
  return (
    <DealerLayout>
      <h1> {t('dealerManagement.dealerHome.heading')} </h1>

      <p> {t('dealerManagement.dealerHome.navParagraph')} </p>

      <Nav className="flex-column">
        <Link to={DEALER_INFORMATION_ROUTE}>
          {t('dealerManagement.dealerHome.navMenu.dealerInformation')}
        </Link>
      </Nav>
    </DealerLayout>
  );
};
