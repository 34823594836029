import React from 'react';
import { NavLink } from 'react-router-dom';
import { ClientIcon } from 'icons';
import { DEALER_INFORMATION_ROUTE } from 'constants/routes';

export const ManSidebar: React.FC = () => {
  return (
    <aside className="sidebar d-md-flex">
      <NavLink to={DEALER_INFORMATION_ROUTE} className="sidebar-link">
        <ClientIcon />
      </NavLink>
    </aside>
  );
};
