import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetUserListQuery,
  useUpdateNewUserMutation,
} from 'thunks/RTKQuery/user';
import { Spinner2 } from 'components/Spinner2';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { confirmationSlice } from 'slices/confirmation';
import i18n from 'translations';
import { Confirmation } from 'components/Modal';
import { toastSlice } from 'slices';
import { useTranslation } from 'react-i18next';
import { isFetchBaseQueryError } from 'utils/helpers';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { IUserObject } from '../Models/users';
import { UserListView } from './UsersListView';

interface IUserListViewContainer {
  setIsUpdateMode: (mode: boolean) => void;
  setSelectedId: (id: number) => void;
  selectedId: number;
  setErrorString: (err: string) => void;
}

export const UserListViewContainer = ({
  setIsUpdateMode,
  setSelectedId,
  selectedId,
  setErrorString,
}: IUserListViewContainer) => {
  const {
    data: userData = [],
    error: userListError,
    isLoading: userListLoading,
  } = useGetUserListQuery();
  const dataResult = useAppSelector((state) => state.userDetails.data);
  const [userObjectData, setUserObjectData] = useState<IUserObject>();
  useEffect(() => {
    if (userListError && 'message' in userListError) {
      setErrorString(`Error :${userListError.message}`);
    }
  }, [userListError]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [
    updateNewUser,
    { isLoading: userSaveLoading, error: usrSaveError, status: userSaveStatus },
  ] = useUpdateNewUserMutation();

  useEffect(() => {
    if (usrSaveError && isFetchBaseQueryError(usrSaveError)) {
      const msg = usrSaveError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
  }, [usrSaveError]);

  useEffect(() => {
    if (userSaveStatus === QueryStatus.fulfilled && !userSaveStatus) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.save.title'),
          message: t('dealer.save.message'),
        })
      );
    }
  }, [userSaveStatus]);

  const userList = useMemo(() => {
    const data = userData.slice();
    data.sort((a: any, b: any) => {
      return b.id - a.id;
    });
    return data;
  }, [userData]);

  const handleOnSelect = async (userObject: IUserObject) => {
    if (userObject.id) {
      setIsUpdateMode(true);
      setSelectedId(userObject.id);
      const newBlockStatus = !userObject.blocked;
      setUserObjectData({
        ...userObject,
        blocked: !userObject.blocked,
      });
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t(
            newBlockStatus
              ? 'clients.blockedConfirm.title'
              : 'clients.unblockedConfirm.title'
          ),
          message: i18n.t(
            newBlockStatus
              ? 'clients.blockedConfirm.message'
              : 'clients.unblockedConfirm.message'
          ),
          buttonOk: i18n.t(
            newBlockStatus
              ? 'clients.blockedConfirm.buttonOk'
              : 'clients.unblockedConfirm.buttonOk'
          ),
          buttonCancel: i18n.t(
            newBlockStatus
              ? 'clients.blockedConfirm.buttonCancel'
              : 'clients.unblockedConfirm.buttonCancel'
          ),
        })
      );
    }
  };

  const handleBlockedUser = async () => {
    if (selectedId) {
      console.log(userObjectData);
      await updateNewUser(userObjectData || dataResult);
      dispatch(confirmationSlice.actions.close());
    }
  };

  return (
    <>
      <Spinner2 show={userListLoading || userSaveLoading} />
      <UserListView userData={userList} handleOnSelect={handleOnSelect} />
      <Confirmation
        key="mill-users-licenseKey-list"
        onConfirm={handleBlockedUser}
      />
    </>
  );
};
