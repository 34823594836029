import { ICompanyDetail } from '../thunks/settings';
import { IImage } from './image';
import { IUserMillLab } from './mill';

export const MILL_ROLE = 'mill';
export const LAB_ROLE = 'lab';
export const MANAGER_ROLE = 'manager';
export const DEALER_ROLE = 'dealer';

export type Role =
  | typeof MILL_ROLE
  | typeof LAB_ROLE
  | typeof MANAGER_ROLE
  | typeof DEALER_ROLE;

export const MILL_ADMIN_ROLE = 'admin';
export const MILL_USER_ROLE = 'user';

export type MillRole = typeof MILL_ADMIN_ROLE | typeof MILL_USER_ROLE;

export type Provider = 'local';

export interface IRole {
  id: number;
  name: string;
  descriptin: string;
  type: Role;
}

export interface IUserSettings {
  notificationOnNewMessageReceived: boolean;
  notificationOnOrderProcessStart: boolean;
  notificationOnOrderProcessCompleted: boolean;
  sendEmailOnNewMessageReceived: boolean;
  sendEmailOnNewProjectIsCreated: boolean;
  projectDownloadFolderStructure: string;
  language: string;
  pageSize: number;
}

export interface IUser {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  provider: Provider;
  confirmed: boolean;
  blocked: boolean;
  roleId?: number;
  role?: IRole;
  millRole?: MillRole;
  avatar?: IImage;
  language: string;
  notificationNewMessageReceived?: boolean;
  notificationOrderProcessStart?: boolean;
  notificationOrderProcessCompleted?: boolean;
  [key: string]: any;
  user_mill_lab?: [IUserMillLab];
  company?: ICompanyDetail;
  userSettings: string;
  blockedBySystem: boolean;
  blockedByMill: boolean;
}

export interface IContactPerson {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface ILicenseKey {
  id: number;
  licenseKey: string;
  enableDataCollection: boolean;
  [key: string]: any;
}
