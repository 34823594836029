/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getPageSize } from '../../Settings';
import { columns } from './columns';

interface IDealerListView {
  dealerData?: never[];
  handleOnSelect: (row: any) => void;
}

export const DealerListView = ({
  dealerData = [],
  handleOnSelect,
}: IDealerListView) => {
  const { SearchBar } = Search;
  const [pageSize] = useState<number>(+getPageSize());
  const selectRow: SelectRowProps<any> = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: handleOnSelect,
    classes: 'back-color',
  };

  return (
    <ToolkitProvider keyField="id" data={dealerData} columns={columns} search>
      {(props) => (
        <div>
          <SearchBar
            {...props.searchProps}
            className="custome-search-field form-control-bordered"
            style={{ color: 'grey' }}
            delay={1000}
            placeholder="Search! "
          />
          <hr />
          <BootstrapTable
            {...props.baseProps}
            selectRow={selectRow}
            pagination={paginationFactory({
              sizePerPage: pageSize,
              hidePageListOnlyOnePage: true,
            })}
            wrapperClasses="table-dealer-content"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

DealerListView.defaultProps = {
  dealerData: [],
};
