import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillUser } from 'interfaces/Manager/user';
import i18n from 'translations';

export const columns: ColumnDescription<any, IManagerMillUser>[] = [
  {
    dataField: 'id',
    text: 'Id',
    hidden: true,
  },
  {
    dataField: 'dealerId',
    text: i18n.t('dealerManagement.dealerId'),
  },
  {
    dataField: 'name',
    text: i18n.t('dealerManagement.name'),
  },
];
