import React, { useEffect } from 'react';
import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAddNewUserMutation } from 'thunks/RTKQuery/user';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { toastSlice } from 'slices';
import { Spinner2 } from 'components/Spinner2';
import { isFetchBaseQueryError } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { DEALER_ROLE } from 'interfaces/user';
import { IUserObject, IValidationUser } from '../Models/users';
import { UsersDetailView } from './UsersDetailView';

interface IUserDetailContainer {
  isUpdateMode: boolean;
  setErrorString: (err: string) => void;
}
const isDealerRole = (role: any) => {
  return role === DEALER_ROLE;
};
const schema: yup.SchemaOf<IValidationUser> = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  dealerId: yup.string().when('role', {
    is: (val: any) => isDealerRole(val),
    then: yup.string().required(),
  }),
  role: yup.string().required(),
});

export const UsersDetailViewContainer = ({
  isUpdateMode,
  setErrorString,
}: IUserDetailContainer) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataResult = useAppSelector((state) => state.userDetails.data);

  const [
    addNewUser,
    { isLoading: userSaveLoading, error: usrSaveError, status: userSaveStatus },
  ] = useAddNewUserMutation();

  useEffect(() => {
    if (usrSaveError && isFetchBaseQueryError(usrSaveError)) {
      const msg = usrSaveError as any;
      setErrorString(`Error :${msg.data.message.errors}`);
    }
  }, [usrSaveError]);

  const handleSubmit = async (
    values: IUserObject,
    formik: FormikHelpers<IUserObject>
  ) => {
    if (!isUpdateMode) {
      await addNewUser(values);
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (userSaveStatus === QueryStatus.fulfilled && !usrSaveError) {
      dispatch(
        toastSlice.actions.show({
          title: t('dealer.save.title'),
          message: t('dealer.save.message'),
        })
      );
    }
  }, [userSaveStatus]);

  return (
    <>
      <Spinner2 show={userSaveLoading} />
      <UsersDetailView
        isUpdateMode={isUpdateMode}
        detailData={dataResult}
        validationSchema={schema}
        onSubmit={handleSubmit}
      />
    </>
  );
};
