import React, { useEffect, useState } from 'react';

import { authSlice, toastSlice } from 'slices';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Spinner2 } from 'components';
import { useAddNewUserMutation } from 'thunks/RTKQuery/user';
import { isFetchBaseQueryError } from 'utils/helpers';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useUpdateDealerUserIdMutation } from 'thunks/RTKQuery/dealer';
import { InviteUsers } from './InviteUsers';

export const InviteUsersContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showInviteUserModel } = useAppSelector((state) => state.auth);
  const [saveError, setSaveError] = useState('');
  const dataResult = useAppSelector((state) => state.dealerDetails.data);
  const [savedUserId, setSaveUserId] = useState<number>(0);
  const [email, setEmail] = useState('');

  const resetState = () => {
    setSaveError('');
    setSaveUserId(0);
    setEmail('');
  };

  const [
    addNewUser,
    { isLoading: userSaveLoading, error: usrSaveError, status: userSaveStatus },
  ] = useAddNewUserMutation();

  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useUpdateDealerUserIdMutation();

  useEffect(() => {
    if (usrSaveError && isFetchBaseQueryError(usrSaveError)) {
      const msg = usrSaveError as any;
      setSaveError(`Error :${msg.data.message.errors}`);
    }
  }, [usrSaveError]);

  useEffect(() => {
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setSaveError(`Error :${msg.data.message.errors}`);
    }
  }, [dealerUpdateError]);

  useEffect(() => {
    if (userSaveStatus === QueryStatus.fulfilled && !usrSaveError) {
      if (savedUserId !== 0) {
        const values = {
          id: dataResult.id,
          userId: savedUserId,
        };
        updateDealer(values);
        resetState();
      }
    }
  }, [savedUserId]);

  useEffect(() => {
    if (dealerUpdateStatus === QueryStatus.fulfilled && !usrSaveError) {
      dispatch(
        toastSlice.actions.show({
          title: 'Invite Send',
          message: 'Invitation has been sent successfully',
        })
      );
      dispatch(authSlice.actions.closeInviteUserModel());
    }
  }, [dealerUpdateStatus]);

  const onClose = () => {
    resetState();
    dispatch(authSlice.actions.closeInviteUserModel());
  };

  const handleSubmit = async (e: string) => {
    const values = {
      id: 0,
      firstName: dataResult.name,
      lastName: dataResult.dealerId,
      email: e,
      dealerId: dataResult.dealerId,
      role: 'dealer',
      blocked: false,
    };
    const result = await addNewUser(values);

    const resultData = result as any;
    const res = resultData?.data?.user?.id;
    setSaveUserId(res || 0);
  };

  return (
    <>
      <Spinner2 show={userSaveLoading || dealerUpdateLoading} />
      <InviteUsers
        show={showInviteUserModel}
        saveError={saveError}
        email={email}
        setEmail={setEmail}
        userSaveLoading={userSaveLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};
