/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getPageSize } from '../../Settings';
import { columns } from './columns';
import { Actions } from './Actions';
import { IUserObject } from '../Models/users';

interface IUserListView {
  userData?: never[];
  handleOnSelect: (row: any) => void;
}

export const UserListView = ({
  userData = [],
  handleOnSelect,
}: IUserListView) => {
  const { SearchBar } = Search;
  const [pageSize] = useState<number>(+getPageSize());

  const addActionsToTable = () => {
    columns[columns.length - 1].formatter = (
      cell: any,
      userObject: IUserObject
    ) => {
      return Actions({
        userObject,
        showActions: true,
        onBlock: handleOnSelect,
      });
    };
  };

  useEffect(() => {
    (async () => {
      addActionsToTable();
    })();
  }, []);

  return (
    <>
      <ToolkitProvider keyField="id" data={userData} columns={columns} search>
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              className="custome-search-field form-control-bordered"
              style={{ color: 'grey' }}
              delay={1000}
              placeholder="Search! "
            />
            <hr />
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory({
                sizePerPage: pageSize,
                hidePageListOnlyOnePage: true,
              })}
              wrapperClasses="table-dealer-content"
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

UserListView.defaultProps = {
  userData: [],
};
