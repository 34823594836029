export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Deutsch',
    en: 'English',
    it: 'Italian',
    fr: 'French',
    sp: 'Spanish',
    pl: 'Polish',
  },
  search: 'Search',
  goBack: 'Back',
  signIn: {
    title: 'Sign in',
    dontHaveAccount: 'Don’t have an account?',
  },
  signUp: {
    title: 'Sign up',
    info:
      'By signing up you agree to our <0>{{privacy}}</0> and <1>{{terms}}</1>.',
    promotions: "I don't want to receive email updates or promotions",
    alreadyHaveAccount: 'Already have an account?',
    serviceterms: 'I agree to the <0>{{service}}</0>.',
    dataprocessing: 'I agree to the <0>{{agreement}}</0>.',
  },
  signOut: {
    title: 'Sign out',
  },
  email: {
    label: 'Email address',
    placeholder: 'Email address',
    taken: 'Email is already taken',
  },
  password: {
    label: 'Password',
    placeholder: 'Enter password',
    mismatch: 'Passwords must match.',
  },
  passwordConfirmation: {
    label: 'Confirm password',
    placeholder: 'Enter confirm password',
  },
  firstName: {
    label: 'First name',
    placeholder: 'Enter first name',
  },
  lastName: {
    label: 'Last name',
    placeholder: 'Enter last name',
  },
  activationKey: {
    label: 'Activation key',
    placeholder: 'Enter activation key',
  },
  companyName: {
    label: 'Company name',
    placeholder: 'Enter company name',
  },
  contactPerson: {
    label: 'Contact Person',
    placeholder: 'Enter contact person name',
  },
  country: {
    label: 'Country',
  },
  address: {
    label: 'Address',
    placeholder: 'Add address',
  },
  city: {
    label: 'City',
    placeholder: 'Add city',
  },
  zip: {
    label: 'Zip code',
    placeholder: 'Enter Zip code',
  },
  web: {
    label: 'Web',
    placeholder: 'Enter web',
  },
  uid: {
    label: 'TaxId',
    placeholder: 'Enter tax ID',
  },
  vatId: {
    label: 'VAT Number',
    placeholder: 'VAT (e.g: E123456789)',
  },
  state: {
    label: 'State',
    placeholder: 'Enter state',
  },
  street: {
    label: 'Street',
    placeholder: 'Enter street',
  },
  phone: {
    label: 'Phone',
    placeholder: 'Enter phone',
  },
  forgot: {
    title: 'Forgot password',
    info:
      "Dont't worry! Just fill your email and we'll send you a link to reset your password.",
    button: 'Send reset password',
    password: 'Forgot password?',
  },
  header: {
    profile: 'Profile',
    settings: 'Settings',
    companyInfo: 'Company info',
    selectMill: 'Select milling center',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'About us',
        content:
          'Follow-Me! Technology Group is developing and distributing hyperDENT®. A software systems that covers the complete production process with a single CAM software. The headquarter of FOLLOW-ME! Technology GmbH is based in Munich with our CEO Dr. Ferdinand Schwenkert and CTO Günter Linseis, who are consistently innovating new intelligent solutions across our product range, keeping a forward-looking perspective. <br> <br> Our subsidiary offices of the FOLLOW-ME! Technology Group are situated worldwide such as in Italy, China, Japan, Korea, Singapore and the USA.',
        ceoMessage:
          '“We want to provide dental software solutions that create value by improving production processes through digitalization and automatization.”',
      },
      contact: {
        title: 'Contact',
        content:
          '<strong>FOLLOW-ME! TECHNOLOGY GmbH, Regerstrasse 27, 81541 Munich, Germany. <br> Tel.: +49 89 452 170 60 . <br> E-Mail: info@follow-me-tech.com</strong>',
      },
      desktopApp: {
        title: 'Desktop app',
        content: '<strong>Download the Desktop app here</strong>',
      },
      privacyPolicy: {
        title: 'Privacy policy',
        content:
          '<strong>Data protection is a matter of very high importance for FOLLOW-ME! Technology GmbH. Use of our website is generally possible without the need to provide personal data. Personal data is only processed to the extent necessary for the ability to provide a functioning website and the content and services offered through it. The processing of personal data of our users normally only takes place with the user’s prior consent. An exception to this exists in cases where it is not possible to obtain consent in advance due to reasons of fact and where processing of the data is permitted under the provisions of law.</strong>',
      },
      termsOfService: {
        title: 'Terms of service',
        content:
          '<strong>We are currently working on our updated terms of service, please check later to see updated terms of service for using hyperDENT Connect.</strong>',
      },
      dataProcessing: {
        title: 'Data Processing Agreement',
        content:
          '<strong>We are currently working on our updated data processing agreement, please check later to see updated data processing agreement for using hyperDENT Connect.</strong>',
      },
    },
  },
  home: {
    home: 'Home',
    subtitle: 'hyperDENT Connect',
    title: 'Connected with experts',
    content: 'Sharing your digital dental restorations the easy way.',
    reasons: {
      title: 'Why use hyperDENT Connect?',
      reason1: {
        title: 'Digital dentistry is changing the requirements. ',
        content:
          'Higher productivity, more flexibility, more storage space and leaner processes.',
      },
      reason2: {
        title: 'Customise your cloud services.',
        content:
          'Tailor-made, high-performance and scalable cloud services open up completely new possibilities for milling centers to achieve your own goals.',
      },
      reason3: {
        title: 'Easy cloud access ',
        content:
          'hyperDENT Connect gives you access to your customers files and use the application from any device that can access the Internet.',
      },
    },
    policiesupdate: {
      title: 'Policy Update(s)',
      content:
        'Please give your consent on our updated terms of service and data processing agreement.',
    },
  },
  welcome: {
    title: 'Welcome to hyperDENT Connect',
    content:
      'hyperDENT Connect is your platform for sharing digital dental files. Effortless sharing for millingcenter customers, real time status report and easy communication connects millingcenters with their customers. ',
    landingPage: {
      title: '1. Create landing page',
      content: '',
      button: 'Landing page settings',
    },
    inviteClients: {
      title: '2. Invite clients',
      content:
        'Add dental labs or clinics to your customer accounts by inviting them with a predefined invitation link. ',
      button: 'Invite clients',
    },
  },
  labWelcome: {
    title: 'Instructional headline',
    content: 'Instructional headline: Text hier',
    serviceApp: {
      title: 'Service app',
      content: '',
      button: 'Download App',
    },
    dashboard: {
      title: 'Dashboard',
      content: '',
      button: 'Go to Dashboard',
    },
  },
  orders: {
    title: 'Orders',
    deleted: 'Deleted',
    deletedFilesInfo:
      'Files for this project have been deleted because files keeping limit was reached.',
    noSearch: 'No search results',
    noOrders: 'No orders created',
    winApp: 'Orders are created from windows application',
    downloadApp: 'Download application',
    columns: {
      orderId: 'Order Id',
      name: 'Order name',
      date: 'Date',
      messages: 'Messages',
      components: 'Components',
      client: 'Client',
      responsible: 'Project responsible',
      download: 'Download',
      report: 'Report',
    },
    component: {
      columns: {
        name: 'Component',
        status: 'Status',
        messages: 'Messages',
        files: 'Files',
        comment: 'Comment',
      },
    },
    messages: 'messages',
    message: 'message',
    newMessage: 'new',
    select: {
      any: {
        client: 'Any client',
        responsible: 'Any user',
      },
    },
  },
  project: {
    download: {
      flat: 'Flat',
      hierarchical: 'Hierarchical',
    },
  },
  component: {
    orders: 'Orders',
    component: 'Component',
    status: {
      label: 'Component Status',
      any: 'Any status',
      open: 'Open',
      closed: 'Closed',
      downloaded: 'Downloaded',
    },
    orderId: 'Order ID',
    project: 'Project',
    preview: 'Component Preview',
    documents: 'Component documents',
    downloadAll: 'Download all',
    downloads: 'Documents for download',
    uploadPdf: 'Upload Pdf',
    noFiles: 'No files',
    messages: {
      title: 'Messages',
      message: {
        placeholder: 'Write a message',
      },
      button: 'Send message',
    },
    upload: {
      title: 'Add document',
      name: {
        label: 'Document name',
        placeholder: 'Enter document name',
      },
      button: 'Upload',
    },
    download: {
      deleteConfirm: {
        title: 'Delete document',
        message: 'Are you sure you want to delete the document?',
        buttonOk: 'Delete',
        buttonCancel: 'Cancel',
      },
      deleteToast: {
        title: 'Delete document',
        message: 'Document deleted successfully.',
      },
      uploadToast: {
        title: 'Upload document',
        message: 'Document uploaded successfully.',
      },
    },
    updateToast: {
      title: 'Update component',
      message: 'Component has been updated successfully.',
    },
    messageCreateToast: {
      title: 'Send message',
      message: 'Message sent successfully.',
    },
    updateOrderToast: {
      title: 'Update order',
      message: 'Order has been updated successfully.',
    },
  },
  fileUpload: {
    label: 'Drag & drop or click to upload',
  },
  clients: {
    title: 'Clients',
    addClient: 'Add client',
    status: {
      label: 'Status',
      any: 'Any status',
      active: 'Active',
      inactive: 'Inactive',
    },
    columns: {
      company: 'Company',
      email: 'Email',
      lab: 'Lab name',
      contactPerson: 'Contact person',
      status: 'Status',
    },
    actions: {
      sendInvite: 'Send invite',
      delete: 'Delete',
      blocked: 'Block',
      unBlock: 'Unblock',
    },
    deleteConfirm: {
      title: 'Delete client',
      message: 'Are you sure you want to delete the client?',
      buttonOk: 'Delete',
      buttonCancel: 'Cancel',
    },
    blockedConfirm: {
      title: 'Block client',
      message: 'Are you sure you want to block the client?',
      buttonOk: 'Block',
      buttonCancel: 'Cancel',
    },
    unblockedConfirm: {
      title: 'Unblock client',
      message: 'Are you sure you want to unblock the client?',
      buttonOk: 'Unblock',
      buttonCancel: 'Cancel',
    },
    deleteToast: {
      title: 'Delete client',
      message: 'Client deleted successfully.',
    },
    blockToast: {
      title: 'Block client',
      message: 'Client blocked successfully.',
    },
    unblockToast: {
      title: 'Unblock client',
      message: 'Client unblocked successfully.',
    },
    blockNotAllowedToast: {
      title: 'Unblock client',
      message:
        'This client is blocked by system, Please contact your administrator.',
    },
    client: {
      title: 'Add client',
      button: 'Add client',
    },
    activation: {
      title: 'Activation instructions',
      info:
        'Client successfully added. You can copy and use this message template to contact newly added client.',
      message: {
        label: 'Invitation message',
        placeholder: 'Enter invitation message',
      },
      copy: 'Copy message',
      send: 'Send email',
      confirm: {
        title: 'Instruction mail',
        message: 'Mail with instruction has successfully been sent to client',
        buttonOk: 'Close',
      },
      errorConfirm: {
        title: 'Activation instructions error',
        buttonOk: 'Ok',
      },
    },
    millingcenterClientAdded: {
      title: 'Millingcenter client added',
      message:
        "Millingcenter client '{{millClient}}' has been added to workspace.",
      buttonOk: 'Great',
    },
  },
  messages: {
    title: 'Messages',
    status: {
      any: 'Any messages',
      read: 'Read',
      unread: 'Unread',
    },
    noMessages: 'You have no messages',
  },
  settings: {
    title: 'Settings',
    tabs: {
      landingPage: 'Landing Page',
      companyDetails: 'Company details',
      users: 'Users',
      licenseKeys: 'License Keys',
    },
    company: {
      logo: {
        title: 'Company Logo',
        label: 'Logo',
      },
      info: {
        title: 'Company Info',
      },
      button: 'Update',
      message: {
        update: 'Please update your company details.',
      },
      error: {
        title: 'Error Occurred',
        message: 'An error occured while saving landing page details.',
      },
    },
    users: {
      add: 'Add user',
      edit: 'Edit User',
      roles: {
        title: 'Roles',
        all: 'All roles',
        admin: 'Admin',
        user: 'User',
      },
      columns: {
        name: 'Name',
        email: 'Email',
        role: 'Role',
      },
      actions: {
        delete: 'Delete',
        edit: 'Edit',
      },
      deleteConfirm: {
        title: 'Delete user',
        message: 'Are you sure you want to delete the user?',
        buttonOk: 'Delete',
        buttonCancel: 'Cancel',
      },
      addToast: {
        title: 'Add user',
        message: 'User created successfully.',
      },
      editToast: {
        title: 'Edit user',
        message: 'User is updated successfully.',
      },
      deleteToast: {
        title: 'Delete user',
        message: 'User deleted successfully.',
      },
      error: {
        title: 'Error Occurred',
        message: 'An error occured while saving users details.',
      },
    },
    licenseKey: {
      add: 'Add license key',
      columns: {
        licenseKey: 'License Key',
        placeholder: 'Enter license key',
        enableCollection: 'Enable Data Collection',
      },
      actions: {
        delete: 'Delete',
      },
      deleteConfirm: {
        title: 'Delete license Key',
        message: 'Are you sure you want to delete the license key?',
        buttonOk: 'Delete',
        buttonCancel: 'Cancel',
      },
      addToast: {
        title: 'Add license key',
        message: 'License Key added successfully.',
      },
      editToast: {
        title: 'Edit license key',
        message: 'License Key information is updated successfully.',
      },
      deleteToast: {
        title: 'Delete license key',
        message: 'License key deleted successfully.',
      },
      error: {
        title: 'Error Occurred',
        message: 'An error occured while saving license keys details.',
      },
    },
    landingPage: {
      intro: 'Create your landing page.',
      copy: {
        label: 'Copy url',
        button: 'Copy',
      },
      logo: 'Logo',
      header: {
        label: 'Header',
        placeholder: 'Write header title',
      },
      content: {
        label: 'Content',
        placeholder: 'Write a description',
      },
      update: 'Update',
      preview: {
        btnTxt: 'Preview Landing Page',
        closeBtnTxt: 'Close Preview',
        signIn: 'Sign In',
      },
      logoAlt: 'Company logo',
      notify: {
        title: 'Update landing page',
        message:
          'Landing page has not yet been reviewed. Would you like to do it now?',
        okText: 'Proceed',
        cancelText: 'Skip for now',
      },
    },
  },
  imageUpload: {
    button: 'Browse',
  },
  countries: {
    empty: 'Select a country',
    de: 'Germany',
    us: 'United States',
    es: 'Spain',
  },
  support: {
    title: 'Support',
    tabs: {
      contact: 'Contact',
      downloadApp: 'Download App',
    },
    contact: {
      info: 'How can we help?',
      title: 'Write us',
      topic: {
        label: 'Topic',
        placeholder: 'What is your topic?',
      },
      topics: {
        empty: 'Select a Topic',
        topicA: 'Topic A',
        topicB: 'Topic B',
        topicC: 'Topic C',
      },
      message: {
        label: 'Message',
      },
      submit: 'Submit',
      callUs: 'Call us',
      line: 'Support Lines',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Contact Email',
      },
    },
    downloadApp: {
      info: 'Desktop app for your computer',
      download: 'Download',
      toastTitle: 'Download App',
      notFound: 'App is not available',
    },
  },
  account: {
    title: 'Account',
    tabs: {
      company: 'Company info',
      settings: 'Settings',
      profile: 'Profile',
    },
    company: {
      companyInfo: 'Manage your company info.',
      logo: 'Company Logo',
      information: 'Info',
      contact: 'Contact',
      address: 'Address',
      shipping: {
        title: 'Shipping',
        address: 'Shipping address',
        sameAsAddress: 'Same as address',
        street: {
          label: 'Street',
          placeholder: 'Enter shipping street',
        },
        city: {
          label: 'City',
          placeholder: 'Enter shipping city',
        },
        zip: {
          label: 'Zip code',
          placeholder: 'Enter shipping zip code',
        },
      },
      button: 'Update',
    },
    settings: {
      info: 'Manage your personal settings.',
      password: {
        title: 'Password',
        label: 'New password',
        placeholder: 'Type new password',
      },
      passwordConfirmation: {
        label: 'Confirm password',
        placeholder: 'Type & confirm password',
      },
      updatePassword: 'Update password',
      notifications: {
        title: 'Notifications',
        notificationOnNewMessageReceived: 'New message received',
        sendEmailOnNewMessageReceived: 'Email on new message',
        notificationOnNewProjectIsCreated: 'New order created',
        sendEmailOnNewProjectIsCreated: 'Email on new order',
        notificationOnOrderProcessStart: 'Order process start',
        notificationOnOrderProcessCompleted: 'Order process completed',
      },
      language: {
        title: 'Language',
      },
      project: {
        title: 'Project',
        folderStructure: 'Download folder structure',
      },
    },
    profile: {
      info: 'Manage your profile info.',
      button: 'Update',
    },
    updateProfileToast: {
      title: 'Update profile',
      message: 'Profile updated successfully.',
    },
    updatePasswordToast: {
      title: 'Update password',
      message: 'Password updated successfully.',
    },
    updateNotificationToast: {
      title: 'Update notification',
      message: 'Notification updated successfully.',
    },
    updateLanguageToast: {
      title: 'Update language',
      message: 'Language updated successfully.',
    },
    updateCompanyToast: {
      title: 'Update company',
      message: 'Company updated successfully.',
    },
    invalidVAT: {
      title: 'Invalid VAT Number',
      message:
        'The VAT number provided is not correct, please enter a VALID VAT number.',
    },
  },
  switch: {
    on: 'On',
    off: 'Off',
  },
  auth: {
    registerConfirm: {
      title: 'Confirmation link',
      message:
        "We've sent a confirmation link via mail. Please check your mail and activate your account.",
      buttonOk: 'Ok',
    },
    confirmationConfirm: {
      title: 'Sign up',
      message:
        'Welcome aboard {{name}}! Your account is now active. You can start using our platform.',
      buttonOk: 'Great',
    },
    forgotConfirm: {
      title: 'Forgot password confirmation',
      message: 'To reset your password please check your email.',
    },
    resetConfirm: {
      title: 'Reset password confirmation',
      message: 'Your password has been changed successfully.',
    },
    accountCreationConfirm: {
      title: 'Sign up successful',
      error: 'Sign up Error',
      message:
        'Welcome aboard {{name}}! Your account is now active. You can start using our platform.',
      buttonOk: 'Great',
    },
    millCenterAdded: {
      title: 'Millingcenter center added',
      message: "You have joined to '{{millName}}' millingcenter workspace.",
      buttonOk: 'Great',
    },
  },
  reset: {
    title: 'Reset password',
    info: 'Please enter your new password',
    button: 'Change your password',
  },
  accountCreation: {
    title: 'Account creation',
    info: 'To complete yout account creation, please create a password.',
    password: {
      label: 'Create password',
      placeholder: 'Enter password',
    },
    button: 'Activate account',
  },
  invitation: {
    title: 'Invitation',
    emailLabel: 'Email',
    message: "You have been invited to '{{millName}}' workspace.",
    submitBtnText: 'Create new account',
    connectToAccount: 'Connect to an existing account',
    connectToAccountMsg:
      'If you already have an existing hyperflow account, you can add this workspace to it.',
    or: 'Or',
    addMillToAccountTxt: 'Add mill to this account',
    createNewAccount: 'Create new account',
    sendConfirmationEmail: 'Send confirmation email',
    existingAccount: 'Existing account',
    existingAccountMsg:
      'Email you entered is associated with an existing hyperconnect account. You can add new mill to that account or create a new account using a different email address.',
  },
  landingPage: {
    signIn: 'Sign In',
  },
  unsavedChanges: {
    message: 'You have unsaved changes, are you sure you want to leave?',
  },
  contract: {
    title: 'Contract',
    error: 'Error occured',
    tabs: {
      contractType: {
        title: 'Contract Type',
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        resubscribe: 'Resubscribe',
        addOnConfirmation:
          'Files keeping duration for {{addOnDuration}} days (€{{getPromotionalPrice}}/month) is added to your subscription and will be charged in upcoming invoice.',
        addOnUnsubscription:
          'Basic Add-On with minimum file keeping duration is selected with your plan.',
        subscriptionSuccess:
          'You have successfully subscribed to your selected plan',
        subscriptionFailure:
          'An error occured while subscribing to your selected plan. Please contact your administrator or try again later',
        unsubscriptionSuccess:
          'You have successfully unsubscribed from your selected plan',
        unsubscriptionFailure:
          'An error occured while unsubscribing from your selected plan. Please contact your administrator or try again later',
        resubscriptionSuccess:
          'You have successfully resumed your selected plan',
        resubscriptionFailure:
          'An error occured while resuming your selected plan. Please contact your administrator or try again later',
        month: 'Month',
        cancelsAt: 'Subscription ends at: ',
        free: 'Free',
        silver: 'Silver',
        gold: 'Gold',
        basic: 'Basic',
        essential: 'Essential',
        enterprise: 'Enterprise',
        custom: 'Custom',
        storage: '{{dataLimit}}Mbs of cloud storage',
        orders: 'Create upto {{ordersLimit}} orders',
        clients: 'Invite upto {{clients}} clients',
        supportAvailable: 'Support is available',
        supportNotAvailable: 'Support is not available',
        cancel: 'cancel',
        confirm: 'confirm',
        upgrade: 'upgrade',
        cancelConfirmation:
          'Are you sure you want to cancel your subscription?',
        resubscriptionConfirmation:
          'Are you sure you want to resume your subscription?',
        subscriptionConfirmation:
          'Your subscription will be resumed and upgraded. Do you confirm?',
        upgradesubscriptionConfirmation:
          'Are you sure you want to upgrade / change your subscription?',
        subscriptionConsent:
          'Thank you for choosing subscription for hyperDENT Connect, please provide your consent to continue.',
        payment: {
          success:
            'Your payment has successfully been received. You will be redirected shortly.',
          failure:
            'Payment process was not successful due to manual interuption or invalid card information. You will be redirected shortly.',
        },
      },
      usageDetail: {
        title: 'Usage Details',
        resourceName: 'Resource Name',
        used: 'Used',
        limit: 'Limit',
        usagePercentage: 'Used Percentage',
        errorMessage: 'An error occured while loading usage details.',
      },
      billing: {
        title: 'Billing',
        custEmail: 'Customer Email',
        custComp: 'Company Name',
        fromPeriod: 'Period From',
        toPeriod: 'Period To',
        amount: 'Amount',
        errorMessage: 'An error occured while loading billing details.',
        manage: 'Manage Billing',
      },
      invoices: {
        title: 'Invoices',
        id: 'Invoice Id',
        itemDesc: 'Description',
        amountDue: 'Amount Due',
        download: 'Download',
        isPaid: 'IsPaid',
        paid: 'Paid',
        due: 'Due',
        errorMessage: 'An error occured while loading invoices list.',
        totalAmountDue: 'Total Amount Due',
        dateDue: 'Due Date',
        upcoming: 'Upcoming Invoice',
        buttonOk: 'OK',
      },
    },
    addons: {
      title: 'Add-Ons',
      keepFiles: 'Files keeping duration',
      days: 'days',
      month: 'month',
    },
  },
  errorBoundary: {
    errorMessage: 'Oops !, Something Went Wrong',
    errorFix: 'Technical Error, we are working to fix it',
  },
  notifications: {
    label: 'Notifications',
    new: 'New Notification',
    none: 'You do not have any notification.',
    markAsUnread: 'Mark as unread',
    filter: {
      all: 'All',
      unread: 'Unread',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Error',
        message: 'Something went wrong while generating PDF report',
      },
      success: {
        title: 'Success',
        message: 'PDF report is generated successfully.',
      },
    },
    dateFormat: 'Report date format',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      dongleId: 'Dongle Id',
      isNetwork: 'Is Network',
      isCommercial: 'Is Commercial',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
  dealerManagement: {
    dealerHome: {
      heading: ' Welcome to the dealers dashboard ',
      navParagraph:
        'You can go to any of the following dashboard views by navigating from the sidebar or from the below mentioned links',
      navMenu: {
        dealerInformation: 'Dealer Information',
      },
    },
    heading: 'Dealers Management',
    invitation: 'Invite as User',
    newUser: 'New Dealer',
    dealerId: 'Dealer Id',
    name: 'Name',
    configurations: {
      parentDealerId: 'Parent Dealer Id',
      heading: 'Configurations',
      baseURL: 'Components Base URL',
      configType: 'Config Type',
      json: 'Configurations Json',
      file: 'Configurations File',
      url: 'Configurations Url',
    },
    contact: {
      dealerContact: 'Dealer Contact',
      supportContact: 'Support Contact',
      salesContact: 'Sales Contact',
      address1: 'Address 1',
      address2: 'Address 2',
      address3: 'Address 3',
      zipCode: 'Zip Code',
      city: 'City',
      district: 'District',
      state: 'State',
      country: 'Country',
      phone: 'Phone',
      email: 'Email',
      webUrl: 'Web Url',
    },
    configOptions: {
      none: 'None',
      fixedJson: 'Fixed Json',
      fixedFile: 'Fixed File',
      dynamicUrl: 'Dynamic Url',
    },
    buttonSave: 'Save',
    user: {
      heading: 'User Management',
      newUser: 'New User',
      dealer: 'Dealer',
      manager: 'Manager',
      columns: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
      },
    },
  },
};
