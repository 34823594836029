import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillUser } from 'interfaces/Manager/user';
import i18n from 'translations';

export const columns: ColumnDescription<any, IManagerMillUser>[] = [
  {
    dataField: 'firstName',
    text: i18n.t('dealerManagement.user.columns.firstName'),
  },
  {
    dataField: 'lastName',
    text: i18n.t('dealerManagement.user.columns.lastName'),
  },
  {
    dataField: 'email',
    text: i18n.t('dealerManagement.user.columns.email'),
  },
  {
    dataField: 'role.name',
    text: i18n.t('dealerManagement.user.columns.role'),
  },
  {
    dataField: 'actions',
    text: i18n.t('dealerManagement.user.columns.actions'),
    isDummyField: true,
  },
];
