import React, { useEffect, useState } from 'react';
import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { Col } from 'react-bootstrap';
import { Spinner2 } from 'components';
import { useAppSelector } from 'store/hooks';
import { useGetDealerByUserIdQuery } from 'thunks/RTKQuery/dealer';
import { DealersDetailViewContainer } from '../../Manager/DealersManagement/DealersDetailView/DealersDetailViewContainer';

export const DealersView: React.FC = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [errorString, setErrorString] = useState('');
  const isUpdateMode = true;
  const { data } = useAppSelector((state) => state.auth);
  const user = data?.user;
  const { data: detailData, isLoading, error } = useGetDealerByUserIdQuery(
    user.id,
    user?.id === 0 ? { skip: true } : {}
  );

  useEffect(() => {
    setSelectedId(detailData ? detailData.id : 0);
  }, [detailData]);

  return (
    <DealerLayout>
      <h1 className="ml-2 mb-4 mt-3">
        <Col md={2} />
        <Col md={6}>Dealers Management</Col>
        <Col md={4} />
      </h1>
      <hr />
      <Spinner2 show={isLoading} />

      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={4}>
          <div className="alert alert-error text-left font-size-sm">
            {errorString || error}
          </div>
        </Col>

        <Col md={6} />

        <Col md={2} />
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={2} />
        <Col md={8}>
          <DealersDetailViewContainer
            isUpdateMode={isUpdateMode}
            selectedId={selectedId}
            setErrorString={setErrorString}
          />
        </Col>
        <Col md={2} />
      </div>
    </DealerLayout>
  );
};
