import React from 'react';
import { Link } from 'react-router-dom';

import { TableActions } from 'components/Table/TableActions';
import i18n from 'translations';
import { IUserObject } from '../Models/users';

export interface IActionsProps {
  userObject: IUserObject;
  showActions: boolean;
  onBlock: (userObject: IUserObject) => void;
}

const showText = (isBlocked: boolean) => {
  if (isBlocked) return 'clients.actions.unBlock';
  return 'clients.actions.blocked';
};

export const Actions = ({
  userObject,
  showActions,
  onBlock,
}: IActionsProps) => {
  return (
    <TableActions>
      {showActions && (
        <>
          <Link
            data-cy="delete-licenseKey-action"
            to="?tab=license-keys"
            onClick={() => onBlock(userObject)}
          >
            {i18n.t(showText(userObject.blocked))}
          </Link>
        </>
      )}
    </TableActions>
  );
};
