import React, { useEffect } from 'react';

import { LAB_ROLE } from 'interfaces/mill';
import { Spinner } from '..';
import {
  DEALER_HOME_ROUTE,
  LAB_WELCOME_ROUTE,
  MANAGER_HOME_ROUTE,
  MILL_ORDERS_ROUTE,
  MILL_WELCOME_ROUTE,
  ROOT_ROUTE,
} from '../../constants/routes';
import {
  useLabRole,
  useManagerRole,
  useMillAdminRole,
  useMillRole,
  useMillUserRole,
  useDealerRole,
} from '../../hooks/role';
import {
  DEALER_ROLE,
  MANAGER_ROLE,
  MILL_ADMIN_ROLE,
  MILL_ROLE,
  MillRole,
  Role,
} from '../../interfaces/user';
import { isTokenPresent } from '../../slices/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { meThunk } from '../../thunks/auth';
import { PrivateRoute } from './PrivateRoute';

interface IPrivateRouteContainer {
  path: string;
  component: React.ComponentType<any>;
  userRole: Role;
  millRole?: MillRole;
  redirectTo?: string;
}

export const PrivateRouteContainer: React.FC<IPrivateRouteContainer> = ({
  path,
  component,
  userRole,
  millRole = undefined,
  redirectTo = ROOT_ROUTE,
  ...rest
}: IPrivateRouteContainer) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, apiStatus, data } = useAppSelector(
    (state) => state.auth
  );

  const isMill = useMillRole();
  const isLab = useLabRole();
  const isManager = useManagerRole();
  const isDealer = useDealerRole();

  let isRole;
  switch (userRole) {
    case MILL_ROLE:
      isRole = isMill;
      break;
    case LAB_ROLE:
      isRole = isLab;
      break;
    case MANAGER_ROLE:
      isRole = isManager;
      break;
    case DEALER_ROLE:
      isRole = isDealer;
      break;
    default:
      isRole = isMill;
      break;
  }

  const isMillRole =
    millRole === MILL_ADMIN_ROLE ? useMillAdminRole() : useMillUserRole();

  const canMillAccess = millRole ? isMillRole : true;

  let redirect = redirectTo;
  if (isMill) {
    redirect = MILL_ORDERS_ROUTE;
    if (millRole === MILL_ADMIN_ROLE) {
      redirect = data.ordersCount ? redirect : MILL_WELCOME_ROUTE;
    }
  } else if (isLab) {
    redirect = LAB_WELCOME_ROUTE;
  } else if (isManager) {
    redirect = MANAGER_HOME_ROUTE;
  } else if (isDealer) {
    redirect = DEALER_HOME_ROUTE;
  }

  useEffect(() => {
    if (isTokenPresent() && !isAuthenticated) {
      dispatch(meThunk());
    }
  }, []);

  return !isTokenPresent() || apiStatus === 'fulfilled' ? (
    <PrivateRoute
      path={path}
      isAvailable={isAuthenticated && isRole && canMillAccess}
      component={component}
      redirectTo={redirect}
      {...rest}
    />
  ) : (
    <Spinner />
  );
};

PrivateRouteContainer.defaultProps = {
  redirectTo: ROOT_ROUTE,
  millRole: undefined,
};
