import { createSlice } from '@reduxjs/toolkit';
import { IApiState } from 'interfaces/api';
import { IUserObject } from 'pages/Manager/UserManagement/Models/users';

export interface IUserDetailState extends IApiState {
  data: IUserObject;
}

export const initialState: IUserDetailState = {
  data: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    dealerId: '',
    role: '',
    blocked: false,
  },
};

export const userDetailSlice = createSlice({
  name: 'dealer-detail-slice',
  initialState,
  reducers: {
    setDetailData(state: IUserDetailState, action: any) {
      state.data = action.payload ?? state.data;
    },
    resetData() {
      return { ...initialState };
    },
  },
});
