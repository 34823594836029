import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_TOKEN } from 'constants/constants';
import {
  IDealerObject,
  IDealerUserObject,
} from 'pages/Manager/DealersManagement/Models/dealers';

export interface DealerListView {
  id: number;
  name: string;
  dealerId: string;
}
const token = localStorage.getItem(API_TOKEN);

export const dealerApi = createApi({
  reducerPath: 'dealerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ACTIVITY_BASE_URL,
    prepareHeaders: (headers) => {
      const authToken = localStorage.getItem(API_TOKEN);
      if (authToken) {
        headers.set('Authorization', `Bearer ${authToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Dealers', 'OneDealer'],
  endpoints: (builder) => ({
    getDealerList: builder.query<[], string>({
      query: (props) => `/v1/dealers-informations?props=${props}`,
      providesTags: ['Dealers'],
    }),
    getOneDealer: builder.query<IDealerObject, number>({
      query: (id) => `/v1/dealers-informations/${id}`,
      providesTags: ['OneDealer'],
    }),
    getDealerByUserId: builder.query<IDealerObject, number>({
      query: (id) => `/v1/dealers-informations-user/${id}`,
      providesTags: ['OneDealer'],
    }),
    addNewDealer: builder.mutation<[], IDealerObject>({
      query: (data) => {
        const formData = new FormData();
        const fileIsSelected = !!data?.files?.configFile;
        if (fileIsSelected) {
          formData.append('files.fixedConfigFile', data?.files?.configFile[0]);
        }
        const logoIsSelected = !!data.files?.logo;
        if (logoIsSelected) {
          formData.append('files.logo', data.files?.logo[0]);
        }
        formData.append('data', JSON.stringify({ ...data, files: undefined }));
        return {
          url: '/v1/dealers-informations',
          method: 'Post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Dealers', 'OneDealer'],
    }),
    updateDealer: builder.mutation<[], IDealerObject>({
      query: (data) => {
        const formData = new FormData();
        const fileIsSelected = !!data?.files?.configFile;
        if (fileIsSelected) {
          formData.append('files.fixedConfigFile', data?.files?.configFile[0]);
        }
        const logoIsSelected = !!data.files?.logo;
        if (logoIsSelected) {
          formData.append('files.logo', data.files?.logo[0]);
        }
        formData.append('data', JSON.stringify({ ...data, files: undefined }));
        return {
          url: `/v1/dealers-informations/${data.id}`,
          method: 'Put',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['Dealers', 'OneDealer'],
    }),
    updateDealerUserId: builder.mutation<[], IDealerUserObject>({
      query: (data) => {
        return {
          url: `/v1/dealers-informations-user/${data.id}`,
          method: 'Put',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        };
      },
      invalidatesTags: ['Dealers', 'OneDealer'],
    }),
  }),
});

export const {
  useGetDealerListQuery,
  useGetOneDealerQuery,
  useGetDealerByUserIdQuery,
  useAddNewDealerMutation,
  useUpdateDealerMutation,
  useUpdateDealerUserIdMutation,
} = dealerApi;
