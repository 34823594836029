import React, { useState } from 'react';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { userDetailSlice } from 'slices';
import { useTranslation } from 'react-i18next';
import { UserListViewContainer } from './UsersListView/UsersListViewContainer';
import { UsersDetailViewContainer } from './UsersDetailView/UsersDetailViewContainer';

export const UsersManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState(0);
  const [errorString, setErrorString] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const { t } = useTranslation();

  const createUser = () => {
    setIsUpdateMode(false);
    dispatch(userDetailSlice.actions.resetData());
  };

  return (
    <ManagerLayout>
      <h1 className="ml-2 mb-4 mt-3">{t('dealerManagement.user.heading')}</h1>
      <hr />

      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={6} />
        <Col md={4}>
          <div className="alert alert-error text-left font-size-sm">
            {errorString}
          </div>
        </Col>
        <Col md={2}>
          <Button
            onClick={createUser}
            className="btn-outline-primary create-button"
          >
            {t('dealerManagement.user.newUser')}
          </Button>
        </Col>
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={6} className="mr-20">
          <UserListViewContainer
            setIsUpdateMode={setIsUpdateMode}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            setErrorString={setErrorString}
          />
        </Col>
        <Col md={6}>
          <UsersDetailViewContainer
            isUpdateMode={isUpdateMode}
            setErrorString={setErrorString}
          />
        </Col>
      </div>
    </ManagerLayout>
  );
};
