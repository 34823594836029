import React, { useState } from 'react';
import { Button } from 'components';
import { TAB_FORM_IS_DIRTY } from 'constants/constants';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { Prompt } from 'react-router-dom';
import { setSessionStorage } from 'utils/localStorage';
import { useTranslation } from 'react-i18next';
import i18n from 'translations';
import { IOption, Select } from 'components/Select';
import { MANAGER_ROLE } from 'interfaces/user';
import { IUserObject } from '../Models/users';

interface IUserDetailFormProps {
  isUpdateMode: boolean;
  detailData: IUserObject;
  validationSchema: any | (() => any);
  onSubmit: (
    values: IUserObject,
    form: FormikHelpers<IUserObject>
  ) => void | Promise<any>;
}

export const roleOptions = [
  { value: 'dealer', label: i18n.t('dealerManagement.user.dealer') },
  { value: 'manager', label: i18n.t('dealerManagement.user.manager') },
];

export const roleSearchOptions: IOption[] = [
  { value: '', label: i18n.t('settings.users.roles.all') },
  ...roleOptions,
];

export const UsersDetailView = ({
  isUpdateMode,
  detailData,
  validationSchema,
  onSubmit,
}: IUserDetailFormProps) => {
  const { t } = useTranslation();
  const [isManager, setIsManager] = useState<boolean>(false);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={detailData}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          dirty,
          setFieldValue,
        }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="button"
                      eventKey="0"
                      style={{
                        backgroundColor: 'lavender',
                        width: '100%',
                      }}
                    >
                      Add New User
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Form
                        data-cy="user-profile"
                        onSubmit={handleSubmit}
                        className="form-control-bordered mb-60"
                      >
                        <Row>
                          <Col md={1} />
                          <Col md={10}>
                            <Form.Group
                              controlId="dealerId"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 text-left">
                                {t('dealerManagement.dealerId')}
                              </Form.Label>
                              <Form.Control
                                className="col-sm-7 form-control-bordered"
                                name="dealerId"
                                placeholder={t('dealerManagement.dealerId')}
                                value={values?.dealerId}
                                onChange={handleChange}
                                isInvalid={
                                  touched.dealerId && !!errors.dealerId
                                }
                                disabled={isUpdateMode || isManager}
                              />
                            </Form.Group>

                            <Form.Group
                              controlId="firstName"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 text-left">
                                {t('dealerManagement.user.columns.firstName')}
                              </Form.Label>
                              <Form.Control
                                className="col-sm-7 form-control-bordered"
                                name="firstName"
                                placeholder={t(
                                  'dealerManagement.user.columns.firstName'
                                )}
                                value={values?.firstName}
                                onChange={handleChange}
                                isInvalid={
                                  touched.firstName && !!errors.firstName
                                }
                                disabled={isUpdateMode}
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="lastName"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 text-left">
                                {t('dealerManagement.user.columns.lastName')}
                              </Form.Label>
                              <Form.Control
                                className="col-sm-7 form-control-bordered"
                                name="lastName"
                                placeholder={t(
                                  'dealerManagement.user.columns.lastName'
                                )}
                                value={values?.lastName}
                                onChange={handleChange}
                                isInvalid={
                                  touched.lastName && !!errors.lastName
                                }
                                disabled={isUpdateMode}
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="email"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 text-left">
                                {t('dealerManagement.user.columns.email')}
                              </Form.Label>
                              <Form.Control
                                className="col-sm-7 form-control-bordered"
                                name="email"
                                placeholder={t(
                                  'dealerManagement.user.columns.email'
                                )}
                                value={values?.email}
                                onChange={handleChange}
                                isInvalid={touched.email && !!errors.email}
                                disabled={isUpdateMode}
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="role"
                              className="form-inline form-inline-dealer form-inline-dealer-role"
                            >
                              <Form.Label className="col-sm-3 text-left">
                                {t('settings.users.columns.role')}
                              </Form.Label>
                              <Select
                                isDisabled={isUpdateMode}
                                options={roleOptions}
                                onChange={(option) => {
                                  setFieldValue('role', option.value);
                                  setIsManager(option.value === MANAGER_ROLE);
                                }}
                                value={roleOptions.find(
                                  (rm) => rm.value === values.role
                                )}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={1} />
                        </Row>
                        <Row className="mt-8 mt-10">
                          <Col md={8} />
                          <Col md={4}>
                            <Button
                              dimension="lg"
                              type="submit"
                              className="search-btn save-button"
                              disabled={isUpdateMode}
                            >
                              {t('dealerManagement.buttonSave')}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
          );
        }}
      </Formik>
    </>
  );
};

UsersDetailView.defaultProps = {};
