import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  API_TOKEN,
  APP_DEFAULT_LANGUAGE,
  APP_LANGUAGE,
} from '../constants/constants';

export const API_BASE_URL =
  `${process.env.REACT_APP_API_BASE_URL}/` || 'http://localhost:1337/';

export const ACTIVITY_BASE_URL = `${
  process.env.REACT_APP_ACTIVITY_BASE_URL || 'http://localhost:1338'
}/`;

export interface IAxiosHeader {
  [name: string]: any;
}

let axiosInstance: AxiosInstance;
const axiosOptions = { baseURL: API_BASE_URL };

export default class Api {
  static get instance() {
    if (!axiosInstance) {
      axiosInstance = axios.create(axiosOptions);
    }

    return axiosInstance;
  }

  public static get(url: string, options: AxiosRequestConfig = {}) {
    return Api.request({ url, method: 'get', ...options });
  }

  public static async post(
    url: string,
    data: any,
    options: AxiosRequestConfig = {}
  ) {
    return Api.request({ url, data, method: 'post', ...options });
  }

  public static patch(
    url: string,
    data: any,
    options: AxiosRequestConfig = {}
  ) {
    return Api.request({ url, data, method: 'patch', ...options });
  }

  public static put(url: string, data: any, options: AxiosRequestConfig = {}) {
    return Api.request({ url, data, method: 'put', ...options });
  }

  public static delete(url: string, options: AxiosRequestConfig = {}) {
    return Api.request({ url, method: 'delete', ...options });
  }

  protected static async request(params: AxiosRequestConfig) {
    const headers = { ...params.headers } as IAxiosHeader;

    const token = localStorage.getItem(API_TOKEN);
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    let { url } = params;
    const locale = localStorage.getItem(APP_LANGUAGE) || APP_DEFAULT_LANGUAGE;
    if (url && locale) {
      if (url.includes('?')) {
        url += `&_locale=${locale}`;
      } else {
        url += `?_locale=${locale}`;
      }
    }

    return Api.instance
      .request({
        method: params.method,
        ...params,
        data: params.data,
        url,
        headers,
      })
      .then((response) => response.data);
  }
}
