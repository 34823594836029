import React from 'react';
import { Button, Select } from 'components';
import { CONFIGURATION_TYPE, FILE_TYPES } from 'constants/constants';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { UploadComponent } from 'components/Upload/UploadComponent';
import i18n from 'translations';
import { useManagerRole } from 'hooks/role';
import { IDealerObject } from '../Models/dealers';

interface IDealerDetailFormProps {
  detailData: IDealerObject;
  imageUri: string;
  validationSchema: any | (() => any);
  onSubmit: (values: IDealerObject) => void | Promise<any>;
}

export const DealersDetailView = ({
  detailData,
  imageUri,
  validationSchema,
  onSubmit,
}: IDealerDetailFormProps) => {
  const { t } = useTranslation();
  const managerUser = useManagerRole();

  const CONFIGOPTIONS = [
    {
      value: 'None',
      label: i18n.t('dealerManagement.configOptions.none'),
    },
    {
      value: 'FixedJson',
      label: i18n.t('dealerManagement.configOptions.fixedJson'),
    },
    {
      value: 'FixedFile',
      label: i18n.t('dealerManagement.configOptions.fixedFile'),
    },
    {
      value: 'DynamicUrl',
      label: i18n.t('dealerManagement.configOptions.dynamicUrl'),
    },
  ];

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={detailData}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <Form
                data-cy="user-profile"
                onSubmit={handleSubmit}
                className="form-control-bordered"
              >
                <Row>
                  <Col md={10}>
                    <Form.Group
                      controlId="dealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.dealerId')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="dealerId"
                        placeholder={t('dealerManagement.dealerId')}
                        value={values?.dealerId}
                        onChange={handleChange}
                        isInvalid={touched.dealerId && !!errors.dealerId}
                        disabled={!managerUser}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="name"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.name')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="name"
                        placeholder={t('dealerManagement.name')}
                        value={values?.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                        disabled={!managerUser}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="parentDealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.configurations.parentDealerId')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="parentDealerId"
                        placeholder={t(
                          'dealerManagement.configurations.parentDealerId'
                        )}
                        value={values?.parentDealerId}
                        onChange={handleChange}
                        disabled={!managerUser}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="logo" className="mb-36">
                      <UploadComponent
                        key={Date.now()}
                        setFieldValue={setFieldValue}
                        accept="image/*"
                        values={values?.files?.logo}
                        propField="files.logo"
                        fileType={FILE_TYPES.IMAGE}
                        url={imageUri}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="0"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.configurations.heading')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Form.Group
                          controlId="componentBaseURL"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.configurations.baseURL')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="componentBaseURL"
                            placeholder={t(
                              'dealerManagement.configurations.baseURL'
                            )}
                            value={
                              values?.componentBaseURL
                                ? values?.componentBaseURL
                                : ''
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="configurationType"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.configurations.configType')}
                          </Form.Label>
                          <div className="col-sm-5 form-control-bordered padding-zero">
                            <Select
                              value={CONFIGOPTIONS.find(
                                (opt) => opt.value === values?.configurationType
                              )}
                              onChange={(configType: any) => {
                                setFieldValue(
                                  'configurationType',
                                  configType.value
                                );
                              }}
                              options={CONFIGOPTIONS}
                            />
                          </div>
                        </Form.Group>
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.FIXED_JSON && (
                          <Form.Group
                            controlId="fixedConfigJson"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              {t('dealerManagement.configurations.json')}
                            </Form.Label>
                            <Form.Control
                              className="col-sm-5 form-control-bordered jsonObject"
                              name="fixedConfigJson"
                              placeholder={t(
                                'dealerManagement.configurations.json'
                              )}
                              value={values?.fixedConfigJson}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        )}
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.FIXED_FILE && (
                          <Form.Group className="form-inline form-inline-dealer">
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              {t('dealerManagement.configurations.file')}
                            </Form.Label>
                            <UploadComponent
                              setFieldValue={setFieldValue}
                              accept=".json"
                              values={values?.files?.configFile}
                              fileType={FILE_TYPES.FILE}
                              propField="files.configFile"
                              path={
                                values?.fixedConfigFile?.name ||
                                values?.files?.configFile[0]?.name
                              }
                            />
                          </Form.Group>
                        )}
                        {values?.configurationType ===
                          CONFIGURATION_TYPE.DYNAMIC_URL && (
                          <Form.Group
                            controlId="dynamicConfigUrlPath"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              {t('dealerManagement.configurations.url')}
                            </Form.Label>
                            <Form.Control
                              className="col-sm-5 form-control-bordered"
                              name="dynamicConfigUrlPath"
                              disabled={
                                !(
                                  values?.configurationType ===
                                  CONFIGURATION_TYPE.DYNAMIC_URL
                                )
                              }
                              placeholder={t(
                                'dealerManagement.configurations.url'
                              )}
                              value={
                                values?.dynamicConfigUrlPath
                                  ? values?.dynamicConfigUrlPath
                                  : ''
                              }
                              onChange={handleChange}
                            />
                          </Form.Group>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="1"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.dealerContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <Form.Group
                          controlId="dealerContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address1')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address1"
                            placeholder={t('dealerManagement.contact.address1')}
                            value={values?.dealerContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address2')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address2"
                            placeholder={t('dealerManagement.contact.address2')}
                            value={values?.dealerContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address3')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address3"
                            placeholder={t('dealerManagement.contact.address3')}
                            value={values?.dealerContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.zipCode')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.zipCode"
                            placeholder={t('dealerManagement.contact.zipCode')}
                            value={values?.dealerContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.city')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.city"
                            placeholder={t('dealerManagement.contact.city')}
                            value={values?.dealerContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.district')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.district"
                            placeholder={t('dealerManagement.contact.district')}
                            value={values?.dealerContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.state')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.state"
                            placeholder={t('dealerManagement.contact.state')}
                            value={values?.dealerContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.country')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.country"
                            placeholder={t('dealerManagement.contact.country')}
                            value={values?.dealerContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.dealerContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.dealerContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.dealerContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="2"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.supportContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <Form.Group
                          controlId="supportContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address1')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address1"
                            placeholder={t('dealerManagement.contact.address1')}
                            value={values?.supportContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address2')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address2"
                            placeholder={t('dealerManagement.contact.address2')}
                            value={values?.supportContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address3')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.address3"
                            placeholder={t('dealerManagement.contact.address3')}
                            value={values?.supportContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.zipCode')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.zipCode"
                            placeholder={t('dealerManagement.contact.zipCode')}
                            value={values?.supportContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.city')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.city"
                            placeholder={t('dealerManagement.contact.city')}
                            value={values?.supportContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.district')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.district"
                            placeholder={t('dealerManagement.contact.district')}
                            value={values?.supportContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.state')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.state"
                            placeholder={t('dealerManagement.contact.state')}
                            value={values?.supportContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.country')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.country"
                            placeholder={t('dealerManagement.contact.country')}
                            value={values?.supportContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.supportContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.supportContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.supportContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="3"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.salesContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <Form.Group
                          controlId="salesContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address1')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address1"
                            placeholder={t('dealerManagement.contact.address1')}
                            value={values?.salesContact?.address1}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address2')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address2"
                            placeholder={t('dealerManagement.contact.address2')}
                            value={values?.salesContact?.address2}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address3')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.address3"
                            placeholder={t('dealerManagement.contact.address3')}
                            value={values?.salesContact?.address3}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.zipCode')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.zipCode"
                            placeholder={t('dealerManagement.contact.zipCode')}
                            value={values?.salesContact?.zipCode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.city')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.city"
                            placeholder={t('dealerManagement.contact.city')}
                            value={values?.salesContact?.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.district')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.district"
                            placeholder={t('dealerManagement.contact.district')}
                            value={values?.salesContact?.district}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.state')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.state"
                            placeholder={t('dealerManagement.contact.state')}
                            value={values?.salesContact?.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.country')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.country"
                            placeholder={t('dealerManagement.contact.country')}
                            value={values?.salesContact?.country}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.salesContact?.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.salesContact?.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.salesContact?.webUrl}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Row className="mt-8">
                  <Col md={10} />
                  <Col md={2}>
                    <Button
                      dimension="lg"
                      type="submit"
                      className="search-btn create-button ml-10"
                    >
                      {t('dealerManagement.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

DealersDetailView.defaultProps = {};
