import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LAB_ROLE } from 'interfaces/mill';
import { API_TOKEN } from '../../../constants/constants';
import {
  DEALER_HOME_ROUTE,
  LAB_WELCOME_ROUTE,
  MANAGER_HOME_ROUTE,
  MILL_WELCOME_ROUTE,
  ROOT_ROUTE,
} from '../../../constants/routes';
import { LogoIcon } from '../../../icons';
import { MANAGER_ROLE, MILL_ROLE, DEALER_ROLE } from '../../../interfaces/user';
import { signupSlice } from '../../../slices';
import { authSlice, isTokenPresent } from '../../../slices/auth';
import { signinSlice } from '../../../slices/signin';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { meThunk } from '../../../thunks/auth';
import { getLocalStorageItem } from '../../../utils/localStorage';
import { Language } from './Language';

export const PublicHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data } = useAppSelector((state) => state.auth);
  const user = data?.user;

  React.useEffect(() => {
    const jwt = getLocalStorageItem(API_TOKEN);
    if (jwt) {
      dispatch(meThunk());
    }
  }, []);

  const handleSignIn = () => {
    dispatch(signinSlice.actions.show());
  };

  const handleSignUp = () => {
    dispatch(signupSlice.actions.show());
  };

  const goToHome = () => {
    let redirectTo;
    switch (user.role?.type) {
      case MILL_ROLE:
        redirectTo = MILL_WELCOME_ROUTE;
        break;
      case LAB_ROLE:
        redirectTo = LAB_WELCOME_ROUTE;
        break;
      case MANAGER_ROLE:
        redirectTo = MANAGER_HOME_ROUTE;
        break;
      case DEALER_ROLE:
        redirectTo = DEALER_HOME_ROUTE;
        break;
      default:
        redirectTo = ROOT_ROUTE;
        break;
    }
    const payload = {
      redirectTo,
    };
    dispatch(authSlice.actions.gotoHome(payload));
  };

  const handleLogout = () => {
    const payload = { redirectTo: ROOT_ROUTE };
    dispatch(authSlice.actions.logout());
    dispatch(authSlice.actions.gotoHome(payload));
  };

  return (
    <>
      <Navbar expand="md" className="h-100">
        <Navbar.Brand href="/" className="public-header-logo">
          <LogoIcon />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="public-navbar-nav" />
        <Navbar.Collapse id="public-navbar-nav">
          <Nav className="ml-auto align-items-center">
            {isTokenPresent() ? (
              <>
                <Nav.Link data-cy="public-header-home" onClick={goToHome}>
                  {t('home.home')}
                </Nav.Link>
                <Nav.Link
                  data-cy="public-header-signout"
                  onClick={handleLogout}
                >
                  {t('signOut.title')}
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link data-cy="public-header-signin" onClick={handleSignIn}>
                  {t('signIn.title')}
                </Nav.Link>
                <Nav.Link data-cy="public-header-signup" onClick={handleSignUp}>
                  {t('signUp.title')}
                </Nav.Link>
              </>
            )}
            <Language />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
